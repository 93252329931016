import React from 'react'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { Box, Tabs, Divider, CssBaseline, Hidden } from '@material-ui/core'
import renderBloks from '@renderBloks'
import { ContentContainer } from '@system'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
  subNav: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    boxShadow:
      '0px 5px 60px rgba(255, 255, 255, 0.25), 0px 4px 20px 10px rgba(0, 0, 0, 0.1)',
    borderBottom: `1px solid rgba(255, 255, 255, 0.5)`,
  },
  tabContainer: {
    alignItems: 'center',
  },
  subNavTabs: {
    '& a': {
      color: theme.palette.common.black,
      borderBottom: 'none',
      lineHeight: '48px',
      padding: '0px 27px',
      fontWeight: 500,
    },
    '& a:hover': {
      borderBottom: 'none',
    },
  },
  homeLink: {
    '& a': {
      paddingLeft: '0px',
    },
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
  },
  flexItem: {
    height: '35px',
    alignSelf: 'auto',
  },
  homeLinkContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const SubNav = (props) => {
  const { homeLink, items } = props.blok
  const classes = useStyles(props.blok)

  return (
    <SbEditable content={props.blok}>
      <CssBaseline />
      <Hidden smDown>
        <Box className={classes.subNav}>
          <ContentContainer>
            <Tabs
              value={false}
              scrollButtons="on"
              aria-label="side-scrolling tabs"
              classes={{
                flexContainer: classes.tabContainer,
              }}
            >
              {homeLink && (
                <Box className={classes.homeLinkContainer}>
                  <Box
                    className={classNames(classes.subNavTabs, classes.homeLink)}
                  >
                    {renderBloks(homeLink)}
                  </Box>
                  <Divider
                    orientation="vertical"
                    flexItem
                    variant="middle"
                    classes={{
                      root: classes.divider,
                      flexItem: classes.flexItem,
                    }}
                  />
                </Box>
              )}
              <Box className={classes.subNavTabs}>{renderBloks(items)}</Box>
            </Tabs>
          </ContentContainer>
        </Box>
      </Hidden>
    </SbEditable>
  )
}

export default SubNav
